
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as indexhX9janX58DMeta } from "/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/alteracao-de-senha/index.vue?macro=true";
import { default as indexyx6VDMpGj9Meta } from "/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/cadastro/index.vue?macro=true";
import { default as _91uuid_93ySdabWh59xMeta } from "/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/confirmacao-de-email/[uuid].vue?macro=true";
import { default as _91id_93RCF1h6LdC4Meta } from "/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/alugacell/configurar-transporte/[id].vue?macro=true";
import { default as _91id_93EHVGNN348YMeta } from "/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/alugacell/editar-venda/[id].vue?macro=true";
import { default as _91slug_93hxnXttgloqMeta } from "/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/alugacell/em-transito/[slug].vue?macro=true";
import { default as indexQpbrGMOs2gMeta } from "/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/cadastros/aparelhos-alugacell/[uuid]/index.vue?macro=true";
import { default as indexenCaY8BYMQMeta } from "/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/cadastros/aparelhos-alugacell/index.vue?macro=true";
import { default as indexsDN6TK0w68Meta } from "/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/cadastros/aparelhos-alugacell/novo/index.vue?macro=true";
import { default as indexxm6z7cWO1aMeta } from "/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/cadastros/armazens/[uuid]/index.vue?macro=true";
import { default as indexwlf2Tn7jhBMeta } from "/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/cadastros/armazens/index.vue?macro=true";
import { default as indexq4ND0koowZMeta } from "/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/cadastros/armazens/novo/index.vue?macro=true";
import { default as indexwEyOmtWaOkMeta } from "/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/cadastros/campanhas/index.vue?macro=true";
import { default as indexaDpItzv2iXMeta } from "/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/cadastros/campanhas/novo/index.vue?macro=true";
import { default as indexoUhQAWRcwaMeta } from "/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/cadastros/categorias-de-servicos/[uuid]/index.vue?macro=true";
import { default as indexgCcHcrAYJWMeta } from "/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/cadastros/categorias-de-servicos/index.vue?macro=true";
import { default as indexTi87ZreV0LMeta } from "/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/cadastros/categorias-de-servicos/novo/index.vue?macro=true";
import { default as indexKYoBvrN4WCMeta } from "/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/cadastros/dispositivos/[uuid]/index.vue?macro=true";
import { default as indexr9sDhXgAflMeta } from "/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/cadastros/dispositivos/index.vue?macro=true";
import { default as indexUpAt5vKDwzMeta } from "/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/cadastros/dispositivos/novo/index.vue?macro=true";
import { default as index3sRKKrpFU6Meta } from "/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/cadastros/estoques/[uuid]/index.vue?macro=true";
import { default as indexAbEVHwHlhqMeta } from "/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/cadastros/estoques/index.vue?macro=true";
import { default as indexQnRYEqBpbgMeta } from "/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/cadastros/estoques/novo/index.vue?macro=true";
import { default as indexyTLy4zD69iMeta } from "/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/cadastros/importar-mailing/index.vue?macro=true";
import { default as indexHl312kj4e3Meta } from "/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/cadastros/index.vue?macro=true";
import { default as indexCs41KQ2lQ2Meta } from "/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/cadastros/ofertas/[id]/index.vue?macro=true";
import { default as indexbco8Kw1RWJMeta } from "/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/cadastros/ofertas/index.vue?macro=true";
import { default as indexiyrjX484qgMeta } from "/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/cadastros/ofertas/novo/index.vue?macro=true";
import { default as indexmBHM1ZXvB8Meta } from "/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/cadastros/pdvs/[uuid]/index.vue?macro=true";
import { default as indexwi3M9OBn0qMeta } from "/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/cadastros/pdvs/index.vue?macro=true";
import { default as indexfuBMBHR0MgMeta } from "/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/cadastros/pdvs/novo/index.vue?macro=true";
import { default as indexeT8LXkVV9kMeta } from "/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/cadastros/planos/[uuid]/index.vue?macro=true";
import { default as indexqTSukqygfnMeta } from "/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/cadastros/planos/index.vue?macro=true";
import { default as indexqwSEwowCFQMeta } from "/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/cadastros/planos/novo/index.vue?macro=true";
import { default as indexxQdlBuFauPMeta } from "/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/cadastros/provedores/[id]/index.vue?macro=true";
import { default as indexWbEeAkwLQpMeta } from "/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/cadastros/provedores/index.vue?macro=true";
import { default as index0SjWFn5g6PMeta } from "/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/cadastros/provedores/novo/index.vue?macro=true";
import { default as indexF3v7IP6GlpMeta } from "/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/cadastros/redes/[id]/index.vue?macro=true";
import { default as indexmu0SzhUEfvMeta } from "/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/cadastros/redes/index.vue?macro=true";
import { default as indexLJS5ILS60YMeta } from "/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/cadastros/redes/novo/index.vue?macro=true";
import { default as indexVCCLEDnidqMeta } from "/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/cadastros/servicos-ativos/[uuid]/index.vue?macro=true";
import { default as indexeMGIwbht0YMeta } from "/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/cadastros/servicos-ativos/index.vue?macro=true";
import { default as indexHCefjou8FYMeta } from "/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/cadastros/servicos-ativos/novo/index.vue?macro=true";
import { default as indexEcgujAHuVwMeta } from "/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/cadastros/usuarios/[uuid]/index.vue?macro=true";
import { default as index10EQBjkyVbMeta } from "/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/cadastros/usuarios/index.vue?macro=true";
import { default as indexLxLnHxbXfEMeta } from "/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/cadastros/usuarios/novo/index.vue?macro=true";
import { default as index32aR7ixLlGMeta } from "/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/campanhas/campanha-individual/index.vue?macro=true";
import { default as CardViewqKxb5CiyohMeta } from "/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/campanhas/CardView.vue?macro=true";
import { default as indexOSmiPDBq9GMeta } from "/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/campanhas/detalhes-da-campanha/[id]/index.vue?macro=true";
import { default as FilternSdP296MewMeta } from "/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/campanhas/Filter.vue?macro=true";
import { default as indexyP8r6M1MCvMeta } from "/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/campanhas/index.vue?macro=true";
import { default as ListViewzIPLiLNeOtMeta } from "/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/campanhas/ListView.vue?macro=true";
import { default as indexyhLmFYtXL7Meta } from "/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/campanhas/meus-compartilhamentos/index.vue?macro=true";
import { default as indexZv2FY5V8aPMeta } from "/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/central-de-ajuda/ajuda-login/index.vue?macro=true";
import { default as indexfzwiVMz6fsMeta } from "/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/central-de-ajuda/faq/index.vue?macro=true";
import { default as indexhmyc6VZAQuMeta } from "/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/central-de-ajuda/index.vue?macro=true";
import { default as indexccHLwUp5CkMeta } from "/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/central-de-ajuda/pagamentos/index.vue?macro=true";
import { default as indexvpih8cRI6vMeta } from "/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/central-de-ajuda/tutorial-uso-vemqda/index.vue?macro=true";
import { default as indexCmLL4zcPpDMeta } from "/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/chat/index.vue?macro=true";
import { default as indexMvKthXKCtrMeta } from "/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/consultar-produtos/index.vue?macro=true";
import { default as index5Uo7GtnhtEMeta } from "/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/gestao-bko/auditoria-de-venda/index.vue?macro=true";
import { default as indexUTZpmIOvUTMeta } from "/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/gestao-bko/index.vue?macro=true";
import { default as seleciona_45provedorrJiqreMhRKMeta } from "/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/gestao-bko/seleciona-provedor.vue?macro=true";
import { default as indexW82MB6HUV7Meta } from "/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/historico-de-vendas/index.vue?macro=true";
import { default as indexqx5irMU6JaMeta } from "/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/index.vue?macro=true";
import { default as indexOLYEPY3ILUMeta } from "/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/leads/index.vue?macro=true";
import { default as indexPGx3ggZQ1uMeta } from "/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/meu-extrato/index.vue?macro=true";
import { default as CardViewdb0RxJ6WyFMeta } from "/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/ofertas/CardView.vue?macro=true";
import { default as index6OIdflA4KvMeta } from "/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/ofertas/detalhes-da-oferta/[id]/index.vue?macro=true";
import { default as Filter5cKKgatZicMeta } from "/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/ofertas/Filter.vue?macro=true";
import { default as indexVlcUdO47DqMeta } from "/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/ofertas/index.vue?macro=true";
import { default as ListViewAuERrGcXq0Meta } from "/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/ofertas/ListView.vue?macro=true";
import { default as indexmFwIkmb6A2Meta } from "/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/ofertas/meus-compartilhamentos/index.vue?macro=true";
import { default as indexzopAraHc2gMeta } from "/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/planos/index.vue?macro=true";
import { default as index2ufB9ENMREMeta } from "/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/relatorios/extrato-de-comissionamento/index.vue?macro=true";
import { default as indexkRRJABWCA0Meta } from "/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/relatorios/index.vue?macro=true";
import { default as indexwxfe8KeX3sMeta } from "/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/relatorios/relatorio-de-contingencia/index.vue?macro=true";
import { default as indexP5cPK1Iu47Meta } from "/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/relatorios/relatorio-de-credito/index.vue?macro=true";
import { default as indexrLxVNv04jOMeta } from "/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/relatorios/relatorio-de-historico-de-status-substatus/index.vue?macro=true";
import { default as indexcUd3X9EfTWMeta } from "/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/relatorios/relatorio-de-mailings-de-ofertas/index.vue?macro=true";
import { default as indexvseALRFfQaMeta } from "/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/relatorios/relatorio-de-ultimo-acesso/index.vue?macro=true";
import { default as indexoFt36e3GTdMeta } from "/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/suporte/index.vue?macro=true";
import { default as edicao_45de_45bancoIMsXpMCuuNMeta } from "/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/usuario/edicao-de-banco.vue?macro=true";
import { default as aguardando_45confirmacao_45clienterM9HFaxDLQMeta } from "/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/venda/alugacell/v1/aguardando-confirmacao-cliente.vue?macro=true";
import { default as consultaAaWSNVlnqGMeta } from "/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/venda/alugacell/v1/consulta.vue?macro=true";
import { default as dados_45do_45clienteLL3vs3y0bFMeta } from "/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/venda/alugacell/v1/dados-do-cliente.vue?macro=true";
import { default as informacoes_45do_45planoKk3476hcWEMeta } from "/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/venda/alugacell/v1/informacoes-do-plano.vue?macro=true";
import { default as retirada_45confirmadaoFe1Dcx7tYMeta } from "/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/venda/alugacell/v1/retirada-confirmada.vue?macro=true";
import { default as indexXDcGPOmQ4vMeta } from "/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/venda/aparelhos/v1/analise-de-credito/index.vue?macro=true";
import { default as indexBO7joveUjjMeta } from "/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/venda/aparelhos/v1/conclusao-de-pedido/index.vue?macro=true";
import { default as indexDlgRi0xquRMeta } from "/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/venda/aparelhos/v1/confirmacao-de-venda/index.vue?macro=true";
import { default as indexBd3WuOq0lpMeta } from "/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/venda/aparelhos/v1/dados-do-cliente/index.vue?macro=true";
import { default as indexdUqESHpBgkMeta } from "/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/venda/aparelhos/v1/personalizacao-de-pagamento/index.vue?macro=true";
import { default as indexlFLHMtQQYyMeta } from "/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/venda/aparelhos/v1/planos/index.vue?macro=true";
import { default as index6txmDoa2d3Meta } from "/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/venda/edicao-de-venda/[id]/index.vue?macro=true";
import { default as indexDxJMtQFavFMeta } from "/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/venda/escolha-da-categoria/index.vue?macro=true";
import { default as indexY8KNsLbteFMeta } from "/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/venda/escolha-da-operadora/index.vue?macro=true";
import { default as indexpEyxeco51EMeta } from "/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/venda/escolha-do-tipo-de-venda/index.vue?macro=true";
import { default as index6udEPbw4DDMeta } from "/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/venda/seguros-residencial/v1/analise-de-credito/index.vue?macro=true";
import { default as indexjhOgik3sntMeta } from "/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/venda/seguros-residencial/v1/conclusao-de-pedido/index.vue?macro=true";
import { default as indexh3LSXMVd4aMeta } from "/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/venda/seguros-residencial/v1/confirmacao-de-venda/index.vue?macro=true";
import { default as indexpvmsFhv9IJMeta } from "/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/venda/seguros-residencial/v1/dados-do-cliente/index.vue?macro=true";
import { default as indexvRZ4Qr0PtGMeta } from "/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/venda/seguros-residencial/v1/personalizacao-de-pagamento/index.vue?macro=true";
import { default as indexoUitWlZVwsMeta } from "/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/venda/seguros-residencial/v1/planos/index.vue?macro=true";
import { default as indexdxPDeqvn3sMeta } from "/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/venda/seguros/v1/analise-de-credito/index.vue?macro=true";
import { default as indexpepUsxaUwlMeta } from "/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/venda/seguros/v1/conclusao-de-pedido/index.vue?macro=true";
import { default as index4DOlVd6WrqMeta } from "/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/venda/seguros/v1/confirmacao-de-venda/index.vue?macro=true";
import { default as indexlq8DqtReqYMeta } from "/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/venda/seguros/v1/dados-do-cliente/index.vue?macro=true";
import { default as indexuNJ5oOHQJpMeta } from "/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/venda/seguros/v1/personalizacao-de-pagamento/index.vue?macro=true";
import { default as indexvuF1Z9mWJoMeta } from "/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/venda/seguros/v1/planos/index.vue?macro=true";
import { default as indexRZSwXy7QL9Meta } from "/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/venda/seguros/v1/verificacao-usuario/index.vue?macro=true";
import { default as indexI84q8Ele41Meta } from "/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/venda/terra-internet-fixa/v1/analise-de-cobertura/index.vue?macro=true";
import { default as indexSJuu4m3jfRMeta } from "/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/venda/terra-internet-fixa/v1/analise-de-credito/index.vue?macro=true";
import { default as indexRDfJAmf701Meta } from "/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/venda/terra-internet-fixa/v1/conclusao-de-pedido/index.vue?macro=true";
import { default as index1zonhG7RbFMeta } from "/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/venda/terra-internet-fixa/v1/dados-do-cliente/index.vue?macro=true";
import { default as indexUOC8iq7boMMeta } from "/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/venda/terra-internet-fixa/v1/personalizacao-de-pagamento/index.vue?macro=true";
import { default as indexsAVruy68ACMeta } from "/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/venda/terra-internet-fixa/v1/planos/index.vue?macro=true";
import { default as indexq6T5dv4ESEMeta } from "/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/venda/terra-movel/v1/abertura-da-venda/index.vue?macro=true";
import { default as line_45activation_45warnTTDoDCDaMyMeta } from "/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/venda/terra-movel/v1/abertura-da-venda/line-activation-warn.vue?macro=true";
import { default as migrationH8RYpM8MyCMeta } from "/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/venda/terra-movel/v1/abertura-da-venda/migration.vue?macro=true";
import { default as new_45lineFKxeRnWBheMeta } from "/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/venda/terra-movel/v1/abertura-da-venda/new-line.vue?macro=true";
import { default as portabilityJFhpPlCMUQMeta } from "/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/venda/terra-movel/v1/abertura-da-venda/portability.vue?macro=true";
import { default as indexb4ifIh3NuIMeta } from "/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/venda/terra-movel/v1/analise-de-credito/index.vue?macro=true";
import { default as indexhsppPfZ0brMeta } from "/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/venda/terra-movel/v1/confirmacao-de-venda/index.vue?macro=true";
import { default as index2PQulR2HaJMeta } from "/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/venda/terra-movel/v1/dados-do-cliente/index.vue?macro=true";
import { default as indexgudVdRuHLaMeta } from "/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/venda/terra-movel/v1/personalizacao-de-pagamento/index.vue?macro=true";
import { default as index0eIDgignbRMeta } from "/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/venda/terra-movel/v1/planos/index.vue?macro=true";
import { default as indexozljx12EaPMeta } from "/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/venda/vivo-controle-cartao/v1/abertura-da-venda/index.vue?macro=true";
import { default as line_45activation_45warncTHOCCh1RJMeta } from "/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/venda/vivo-controle-cartao/v1/abertura-da-venda/line-activation-warn.vue?macro=true";
import { default as migration2xTjxccqRIMeta } from "/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/venda/vivo-controle-cartao/v1/abertura-da-venda/migration.vue?macro=true";
import { default as new_45lineZW0MfltV1MMeta } from "/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/venda/vivo-controle-cartao/v1/abertura-da-venda/new-line.vue?macro=true";
import { default as portability1IcOpD3reSMeta } from "/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/venda/vivo-controle-cartao/v1/abertura-da-venda/portability.vue?macro=true";
import { default as indexS2RPRXijF5Meta } from "/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/venda/vivo-controle-cartao/v1/analise-de-credito/index.vue?macro=true";
import { default as indexf95gYoDxekMeta } from "/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/venda/vivo-controle-cartao/v1/confirmacao-de-venda/index.vue?macro=true";
import { default as indexDSMlR7Zlk1Meta } from "/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/venda/vivo-controle-cartao/v1/dados-do-cliente/index.vue?macro=true";
import { default as indexOP3YuhJbnaMeta } from "/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/venda/vivo-controle-cartao/v1/personalizacao-de-pagamento/index.vue?macro=true";
import { default as indexUF37EbleTJMeta } from "/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/venda/vivo-controle-cartao/v1/planos/index.vue?macro=true";
import { default as index1W53gsMfN3Meta } from "/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/venda/vivo-movel/v1/abertura-da-venda/index.vue?macro=true";
import { default as line_45activation_45warn7WPo6iXvfGMeta } from "/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/venda/vivo-movel/v1/abertura-da-venda/line-activation-warn.vue?macro=true";
import { default as migrationVwuC4O4BOqMeta } from "/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/venda/vivo-movel/v1/abertura-da-venda/migration.vue?macro=true";
import { default as new_45lineB1yY2RkfyTMeta } from "/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/venda/vivo-movel/v1/abertura-da-venda/new-line.vue?macro=true";
import { default as portabilityyAqkFUwnwbMeta } from "/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/venda/vivo-movel/v1/abertura-da-venda/portability.vue?macro=true";
import { default as indexhVkjNgPYKUMeta } from "/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/venda/vivo-movel/v1/analise-de-credito/index.vue?macro=true";
import { default as indexSJtKGW42G6Meta } from "/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/venda/vivo-movel/v1/conclusao-de-pedido/index.vue?macro=true";
import { default as indexfc0P8exNL1Meta } from "/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/venda/vivo-movel/v1/confirmacao-de-venda/index.vue?macro=true";
import { default as indexF7pCzIlAjuMeta } from "/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/venda/vivo-movel/v1/dados-do-cliente/index.vue?macro=true";
import { default as indexrNgzQXEFsQMeta } from "/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/venda/vivo-movel/v1/personalizacao-de-pagamento/index.vue?macro=true";
import { default as indexWDIiq7F8KhMeta } from "/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/venda/vivo-movel/v1/planos/index.vue?macro=true";
import { default as indexqqEPeWBkinMeta } from "/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/venda/vivo-residencial/v1/analise-de-credito/index.vue?macro=true";
import { default as indexzhiRB94eCRMeta } from "/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/venda/vivo-residencial/v1/conclusao-de-pedido/index.vue?macro=true";
import { default as indexusMt8HkiqhMeta } from "/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/venda/vivo-residencial/v1/confirmacao-de-venda/index.vue?macro=true";
import { default as indexHoEAYvm2lEMeta } from "/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/venda/vivo-residencial/v1/dados-do-cliente/index.vue?macro=true";
import { default as indexcv9R8amiyxMeta } from "/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/venda/vivo-residencial/v1/personalizacao-de-pagamento/index.vue?macro=true";
import { default as indexG4AvebMj4hMeta } from "/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/venda/vivo-residencial/v1/planos/index.vue?macro=true";
import { default as indexYiicBrYwloMeta } from "/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/faq/index.vue?macro=true";
import { default as indexKlAP451CXaMeta } from "/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/index.vue?macro=true";
import { default as index_46clientuzppPtQ9bRMeta } from "/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/login/index.client.vue?macro=true";

let _createClientPage
async function createClientPage(loader) {
  _createClientPage ||= await import("/opt/teamcity/buildAgent/work/f8391997fb1b84f4/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.10.1_db0@0.2.4_eslint@9.20.1_jiti@2.4._912839802084c35a1ec6aad15785acb3/node_modules/nuxt/dist/components/runtime/client-component.js").then(r => r.createClientPage)
  return _createClientPage(loader);
}
import { default as indexVwG952wl2dMeta } from "/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/recuperacao-de-senha/index.vue?macro=true";
import { default as aceite_45de_45vendaws2pCeKAJiMeta } from "/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/alugacell/aceite-de-venda.vue?macro=true";
import { default as indexBwLMcMck10Meta } from "/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/dispositivos/aceite-de-venda/index.vue?macro=true";
import { default as indexlda2QGOQOZMeta } from "/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/dispositivos/venda-aceita/index.vue?macro=true";
import { default as indexsO3ZuTzOGBMeta } from "/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/index.vue?macro=true";
import { default as indexzMtSXtnopAMeta } from "/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/r/[user]/[offer]/[pdv]/index.vue?macro=true";
import { default as indexA21fDPAP8EMeta } from "/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/r/[user]/[offer]/index.vue?macro=true";
import { default as indexuYsUD84jgTMeta } from "/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/seguros-residencial/aceite-de-venda/index.vue?macro=true";
import { default as index804FK4Iw6hMeta } from "/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/seguros-residencial/venda-aceita/index.vue?macro=true";
import { default as indexPNcTyfyQ1FMeta } from "/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/seguros/aceite-de-venda/index.vue?macro=true";
import { default as index8Mi2K2S34lMeta } from "/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/seguros/venda-aceita/index.vue?macro=true";
import { default as indexpq3OlFDQeWMeta } from "/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/t/[promoter]/[offer]/[pdv]/index.vue?macro=true";
import { default as indexAKENRz5cIGMeta } from "/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/t/[promoter]/[offer]/index.vue?macro=true";
import { default as index7rlIdzT0UGMeta } from "/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/terra/agendamento/index.vue?macro=true";
import { default as indexcAe7o6ip1ZMeta } from "/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/terra/internet-fixa/aceite-de-venda/index.vue?macro=true";
import { default as indexMxHn5a5PlAMeta } from "/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/terra/internet-fixa/aceite-finalizado/index.vue?macro=true";
import { default as indexG8uqagetVbMeta } from "/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/terra/internet-fixa/aceite-recusado/index.vue?macro=true";
import { default as indexcu3qwmQmyXMeta } from "/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/terra/internet-fixa/venda-aceita/index.vue?macro=true";
export default [
  {
    name: "theme-alteracao-de-senha",
    path: "/:theme()/alteracao-de-senha",
    component: () => import("/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/alteracao-de-senha/index.vue")
  },
  {
    name: "theme-cadastro",
    path: "/:theme()/cadastro",
    component: () => import("/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/cadastro/index.vue")
  },
  {
    name: "theme-confirmacao-de-email-uuid",
    path: "/:theme()/confirmacao-de-email/:uuid()",
    component: () => import("/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/confirmacao-de-email/[uuid].vue")
  },
  {
    name: "theme-dashboard-alugacell-configurar-transporte-id",
    path: "/:theme()/dashboard/alugacell/configurar-transporte/:id()",
    component: () => import("/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/alugacell/configurar-transporte/[id].vue")
  },
  {
    name: "theme-dashboard-alugacell-editar-venda-id",
    path: "/:theme()/dashboard/alugacell/editar-venda/:id()",
    component: () => import("/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/alugacell/editar-venda/[id].vue")
  },
  {
    name: "theme-dashboard-alugacell-em-transito-slug",
    path: "/:theme()/dashboard/alugacell/em-transito/:slug()",
    component: () => import("/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/alugacell/em-transito/[slug].vue")
  },
  {
    name: "theme-dashboard-cadastros-aparelhos-alugacell-uuid",
    path: "/:theme()/dashboard/cadastros/aparelhos-alugacell/:uuid()",
    component: () => import("/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/cadastros/aparelhos-alugacell/[uuid]/index.vue")
  },
  {
    name: "theme-dashboard-cadastros-aparelhos-alugacell",
    path: "/:theme()/dashboard/cadastros/aparelhos-alugacell",
    component: () => import("/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/cadastros/aparelhos-alugacell/index.vue")
  },
  {
    name: "theme-dashboard-cadastros-aparelhos-alugacell-novo",
    path: "/:theme()/dashboard/cadastros/aparelhos-alugacell/novo",
    component: () => import("/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/cadastros/aparelhos-alugacell/novo/index.vue")
  },
  {
    name: "theme-dashboard-cadastros-armazens-uuid",
    path: "/:theme()/dashboard/cadastros/armazens/:uuid()",
    component: () => import("/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/cadastros/armazens/[uuid]/index.vue")
  },
  {
    name: "theme-dashboard-cadastros-armazens",
    path: "/:theme()/dashboard/cadastros/armazens",
    component: () => import("/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/cadastros/armazens/index.vue")
  },
  {
    name: "theme-dashboard-cadastros-armazens-novo",
    path: "/:theme()/dashboard/cadastros/armazens/novo",
    component: () => import("/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/cadastros/armazens/novo/index.vue")
  },
  {
    name: "theme-dashboard-cadastros-campanhas",
    path: "/:theme()/dashboard/cadastros/campanhas",
    component: () => import("/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/cadastros/campanhas/index.vue")
  },
  {
    name: "theme-dashboard-cadastros-campanhas-novo",
    path: "/:theme()/dashboard/cadastros/campanhas/novo",
    component: () => import("/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/cadastros/campanhas/novo/index.vue")
  },
  {
    name: "theme-dashboard-cadastros-categorias-de-servicos-uuid",
    path: "/:theme()/dashboard/cadastros/categorias-de-servicos/:uuid()",
    component: () => import("/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/cadastros/categorias-de-servicos/[uuid]/index.vue")
  },
  {
    name: "theme-dashboard-cadastros-categorias-de-servicos",
    path: "/:theme()/dashboard/cadastros/categorias-de-servicos",
    component: () => import("/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/cadastros/categorias-de-servicos/index.vue")
  },
  {
    name: "theme-dashboard-cadastros-categorias-de-servicos-novo",
    path: "/:theme()/dashboard/cadastros/categorias-de-servicos/novo",
    component: () => import("/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/cadastros/categorias-de-servicos/novo/index.vue")
  },
  {
    name: "theme-dashboard-cadastros-dispositivos-uuid",
    path: "/:theme()/dashboard/cadastros/dispositivos/:uuid()",
    component: () => import("/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/cadastros/dispositivos/[uuid]/index.vue")
  },
  {
    name: "theme-dashboard-cadastros-dispositivos",
    path: "/:theme()/dashboard/cadastros/dispositivos",
    component: () => import("/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/cadastros/dispositivos/index.vue")
  },
  {
    name: "theme-dashboard-cadastros-dispositivos-novo",
    path: "/:theme()/dashboard/cadastros/dispositivos/novo",
    component: () => import("/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/cadastros/dispositivos/novo/index.vue")
  },
  {
    name: "theme-dashboard-cadastros-estoques-uuid",
    path: "/:theme()/dashboard/cadastros/estoques/:uuid()",
    component: () => import("/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/cadastros/estoques/[uuid]/index.vue")
  },
  {
    name: "theme-dashboard-cadastros-estoques",
    path: "/:theme()/dashboard/cadastros/estoques",
    meta: indexAbEVHwHlhqMeta || {},
    component: () => import("/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/cadastros/estoques/index.vue")
  },
  {
    name: "theme-dashboard-cadastros-estoques-novo",
    path: "/:theme()/dashboard/cadastros/estoques/novo",
    component: () => import("/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/cadastros/estoques/novo/index.vue")
  },
  {
    name: "theme-dashboard-cadastros-importar-mailing",
    path: "/:theme()/dashboard/cadastros/importar-mailing",
    component: () => import("/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/cadastros/importar-mailing/index.vue")
  },
  {
    name: "theme-dashboard-cadastros",
    path: "/:theme()/dashboard/cadastros",
    component: () => import("/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/cadastros/index.vue")
  },
  {
    name: "theme-dashboard-cadastros-ofertas-id",
    path: "/:theme()/dashboard/cadastros/ofertas/:id()",
    component: () => import("/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/cadastros/ofertas/[id]/index.vue")
  },
  {
    name: "theme-dashboard-cadastros-ofertas",
    path: "/:theme()/dashboard/cadastros/ofertas",
    component: () => import("/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/cadastros/ofertas/index.vue")
  },
  {
    name: "theme-dashboard-cadastros-ofertas-novo",
    path: "/:theme()/dashboard/cadastros/ofertas/novo",
    component: () => import("/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/cadastros/ofertas/novo/index.vue")
  },
  {
    name: "theme-dashboard-cadastros-pdvs-uuid",
    path: "/:theme()/dashboard/cadastros/pdvs/:uuid()",
    component: () => import("/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/cadastros/pdvs/[uuid]/index.vue")
  },
  {
    name: "theme-dashboard-cadastros-pdvs",
    path: "/:theme()/dashboard/cadastros/pdvs",
    component: () => import("/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/cadastros/pdvs/index.vue")
  },
  {
    name: "theme-dashboard-cadastros-pdvs-novo",
    path: "/:theme()/dashboard/cadastros/pdvs/novo",
    component: () => import("/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/cadastros/pdvs/novo/index.vue")
  },
  {
    name: "theme-dashboard-cadastros-planos-uuid",
    path: "/:theme()/dashboard/cadastros/planos/:uuid()",
    component: () => import("/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/cadastros/planos/[uuid]/index.vue")
  },
  {
    name: "theme-dashboard-cadastros-planos",
    path: "/:theme()/dashboard/cadastros/planos",
    component: () => import("/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/cadastros/planos/index.vue")
  },
  {
    name: "theme-dashboard-cadastros-planos-novo",
    path: "/:theme()/dashboard/cadastros/planos/novo",
    component: () => import("/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/cadastros/planos/novo/index.vue")
  },
  {
    name: "theme-dashboard-cadastros-provedores-id",
    path: "/:theme()/dashboard/cadastros/provedores/:id()",
    component: () => import("/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/cadastros/provedores/[id]/index.vue")
  },
  {
    name: "theme-dashboard-cadastros-provedores",
    path: "/:theme()/dashboard/cadastros/provedores",
    meta: indexWbEeAkwLQpMeta || {},
    component: () => import("/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/cadastros/provedores/index.vue")
  },
  {
    name: "theme-dashboard-cadastros-provedores-novo",
    path: "/:theme()/dashboard/cadastros/provedores/novo",
    component: () => import("/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/cadastros/provedores/novo/index.vue")
  },
  {
    name: "theme-dashboard-cadastros-redes-id",
    path: "/:theme()/dashboard/cadastros/redes/:id()",
    component: () => import("/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/cadastros/redes/[id]/index.vue")
  },
  {
    name: "theme-dashboard-cadastros-redes",
    path: "/:theme()/dashboard/cadastros/redes",
    component: () => import("/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/cadastros/redes/index.vue")
  },
  {
    name: "theme-dashboard-cadastros-redes-novo",
    path: "/:theme()/dashboard/cadastros/redes/novo",
    component: () => import("/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/cadastros/redes/novo/index.vue")
  },
  {
    name: "theme-dashboard-cadastros-servicos-ativos-uuid",
    path: "/:theme()/dashboard/cadastros/servicos-ativos/:uuid()",
    component: () => import("/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/cadastros/servicos-ativos/[uuid]/index.vue")
  },
  {
    name: "theme-dashboard-cadastros-servicos-ativos",
    path: "/:theme()/dashboard/cadastros/servicos-ativos",
    component: () => import("/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/cadastros/servicos-ativos/index.vue")
  },
  {
    name: "theme-dashboard-cadastros-servicos-ativos-novo",
    path: "/:theme()/dashboard/cadastros/servicos-ativos/novo",
    component: () => import("/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/cadastros/servicos-ativos/novo/index.vue")
  },
  {
    name: "theme-dashboard-cadastros-usuarios-uuid",
    path: "/:theme()/dashboard/cadastros/usuarios/:uuid()",
    component: () => import("/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/cadastros/usuarios/[uuid]/index.vue")
  },
  {
    name: "theme-dashboard-cadastros-usuarios",
    path: "/:theme()/dashboard/cadastros/usuarios",
    component: () => import("/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/cadastros/usuarios/index.vue")
  },
  {
    name: "theme-dashboard-cadastros-usuarios-novo",
    path: "/:theme()/dashboard/cadastros/usuarios/novo",
    component: () => import("/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/cadastros/usuarios/novo/index.vue")
  },
  {
    name: "theme-dashboard-campanhas-campanha-individual",
    path: "/:theme()/dashboard/campanhas/campanha-individual",
    component: () => import("/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/campanhas/campanha-individual/index.vue")
  },
  {
    name: "theme-dashboard-campanhas-CardView",
    path: "/:theme()/dashboard/campanhas/CardView",
    component: () => import("/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/campanhas/CardView.vue")
  },
  {
    name: "theme-dashboard-campanhas-detalhes-da-campanha-id",
    path: "/:theme()/dashboard/campanhas/detalhes-da-campanha/:id()",
    component: () => import("/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/campanhas/detalhes-da-campanha/[id]/index.vue")
  },
  {
    name: "theme-dashboard-campanhas-Filter",
    path: "/:theme()/dashboard/campanhas/Filter",
    component: () => import("/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/campanhas/Filter.vue")
  },
  {
    name: "theme-dashboard-campanhas",
    path: "/:theme()/dashboard/campanhas",
    component: () => import("/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/campanhas/index.vue")
  },
  {
    name: "theme-dashboard-campanhas-ListView",
    path: "/:theme()/dashboard/campanhas/ListView",
    component: () => import("/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/campanhas/ListView.vue")
  },
  {
    name: "theme-dashboard-campanhas-meus-compartilhamentos",
    path: "/:theme()/dashboard/campanhas/meus-compartilhamentos",
    component: () => import("/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/campanhas/meus-compartilhamentos/index.vue")
  },
  {
    name: "theme-dashboard-central-de-ajuda-ajuda-login",
    path: "/:theme()/dashboard/central-de-ajuda/ajuda-login",
    component: () => import("/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/central-de-ajuda/ajuda-login/index.vue")
  },
  {
    name: "theme-dashboard-central-de-ajuda-faq",
    path: "/:theme()/dashboard/central-de-ajuda/faq",
    component: () => import("/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/central-de-ajuda/faq/index.vue")
  },
  {
    name: "theme-dashboard-central-de-ajuda",
    path: "/:theme()/dashboard/central-de-ajuda",
    component: () => import("/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/central-de-ajuda/index.vue")
  },
  {
    name: "theme-dashboard-central-de-ajuda-pagamentos",
    path: "/:theme()/dashboard/central-de-ajuda/pagamentos",
    component: () => import("/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/central-de-ajuda/pagamentos/index.vue")
  },
  {
    name: "theme-dashboard-central-de-ajuda-tutorial-uso-vemqda",
    path: "/:theme()/dashboard/central-de-ajuda/tutorial-uso-vemqda",
    component: () => import("/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/central-de-ajuda/tutorial-uso-vemqda/index.vue")
  },
  {
    name: "theme-dashboard-chat",
    path: "/:theme()/dashboard/chat",
    component: () => import("/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/chat/index.vue")
  },
  {
    name: "theme-dashboard-consultar-produtos",
    path: "/:theme()/dashboard/consultar-produtos",
    component: () => import("/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/consultar-produtos/index.vue")
  },
  {
    name: "theme-dashboard-gestao-bko-auditoria-de-venda",
    path: "/:theme()/dashboard/gestao-bko/auditoria-de-venda",
    component: () => import("/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/gestao-bko/auditoria-de-venda/index.vue")
  },
  {
    name: "theme-dashboard-gestao-bko",
    path: "/:theme()/dashboard/gestao-bko",
    component: () => import("/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/gestao-bko/index.vue")
  },
  {
    name: "theme-dashboard-gestao-bko-seleciona-provedor",
    path: "/:theme()/dashboard/gestao-bko/seleciona-provedor",
    component: () => import("/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/gestao-bko/seleciona-provedor.vue")
  },
  {
    name: "theme-dashboard-historico-de-vendas",
    path: "/:theme()/dashboard/historico-de-vendas",
    component: () => import("/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/historico-de-vendas/index.vue")
  },
  {
    name: "theme-dashboard",
    path: "/:theme()/dashboard",
    component: () => import("/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/index.vue")
  },
  {
    name: "theme-dashboard-leads",
    path: "/:theme()/dashboard/leads",
    component: () => import("/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/leads/index.vue")
  },
  {
    name: "theme-dashboard-meu-extrato",
    path: "/:theme()/dashboard/meu-extrato",
    component: () => import("/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/meu-extrato/index.vue")
  },
  {
    name: "theme-dashboard-ofertas-CardView",
    path: "/:theme()/dashboard/ofertas/CardView",
    component: () => import("/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/ofertas/CardView.vue")
  },
  {
    name: "theme-dashboard-ofertas-detalhes-da-oferta-id",
    path: "/:theme()/dashboard/ofertas/detalhes-da-oferta/:id()",
    component: () => import("/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/ofertas/detalhes-da-oferta/[id]/index.vue")
  },
  {
    name: "theme-dashboard-ofertas-Filter",
    path: "/:theme()/dashboard/ofertas/Filter",
    component: () => import("/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/ofertas/Filter.vue")
  },
  {
    name: "theme-dashboard-ofertas",
    path: "/:theme()/dashboard/ofertas",
    component: () => import("/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/ofertas/index.vue")
  },
  {
    name: "theme-dashboard-ofertas-ListView",
    path: "/:theme()/dashboard/ofertas/ListView",
    component: () => import("/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/ofertas/ListView.vue")
  },
  {
    name: "theme-dashboard-ofertas-meus-compartilhamentos",
    path: "/:theme()/dashboard/ofertas/meus-compartilhamentos",
    component: () => import("/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/ofertas/meus-compartilhamentos/index.vue")
  },
  {
    name: "theme-dashboard-planos",
    path: "/:theme()/dashboard/planos",
    component: () => import("/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/planos/index.vue")
  },
  {
    name: "theme-dashboard-relatorios-extrato-de-comissionamento",
    path: "/:theme()/dashboard/relatorios/extrato-de-comissionamento",
    component: () => import("/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/relatorios/extrato-de-comissionamento/index.vue")
  },
  {
    name: "theme-dashboard-relatorios",
    path: "/:theme()/dashboard/relatorios",
    component: () => import("/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/relatorios/index.vue")
  },
  {
    name: "theme-dashboard-relatorios-relatorio-de-contingencia",
    path: "/:theme()/dashboard/relatorios/relatorio-de-contingencia",
    component: () => import("/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/relatorios/relatorio-de-contingencia/index.vue")
  },
  {
    name: "theme-dashboard-relatorios-relatorio-de-credito",
    path: "/:theme()/dashboard/relatorios/relatorio-de-credito",
    component: () => import("/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/relatorios/relatorio-de-credito/index.vue")
  },
  {
    name: "theme-dashboard-relatorios-relatorio-de-historico-de-status-substatus",
    path: "/:theme()/dashboard/relatorios/relatorio-de-historico-de-status-substatus",
    component: () => import("/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/relatorios/relatorio-de-historico-de-status-substatus/index.vue")
  },
  {
    name: "theme-dashboard-relatorios-relatorio-de-mailings-de-ofertas",
    path: "/:theme()/dashboard/relatorios/relatorio-de-mailings-de-ofertas",
    component: () => import("/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/relatorios/relatorio-de-mailings-de-ofertas/index.vue")
  },
  {
    name: "theme-dashboard-relatorios-relatorio-de-ultimo-acesso",
    path: "/:theme()/dashboard/relatorios/relatorio-de-ultimo-acesso",
    component: () => import("/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/relatorios/relatorio-de-ultimo-acesso/index.vue")
  },
  {
    name: "theme-dashboard-suporte",
    path: "/:theme()/dashboard/suporte",
    component: () => import("/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/suporte/index.vue")
  },
  {
    name: "theme-dashboard-usuario-edicao-de-banco",
    path: "/:theme()/dashboard/usuario/edicao-de-banco",
    component: () => import("/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/usuario/edicao-de-banco.vue")
  },
  {
    name: "theme-dashboard-venda-alugacell-v1-aguardando-confirmacao-cliente",
    path: "/:theme()/dashboard/venda/alugacell/v1/aguardando-confirmacao-cliente",
    component: () => import("/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/venda/alugacell/v1/aguardando-confirmacao-cliente.vue")
  },
  {
    name: "theme-dashboard-venda-alugacell-v1-consulta",
    path: "/:theme()/dashboard/venda/alugacell/v1/consulta",
    component: () => import("/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/venda/alugacell/v1/consulta.vue")
  },
  {
    name: "theme-dashboard-venda-alugacell-v1-dados-do-cliente",
    path: "/:theme()/dashboard/venda/alugacell/v1/dados-do-cliente",
    component: () => import("/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/venda/alugacell/v1/dados-do-cliente.vue")
  },
  {
    name: "theme-dashboard-venda-alugacell-v1-informacoes-do-plano",
    path: "/:theme()/dashboard/venda/alugacell/v1/informacoes-do-plano",
    component: () => import("/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/venda/alugacell/v1/informacoes-do-plano.vue")
  },
  {
    name: "theme-dashboard-venda-alugacell-v1-retirada-confirmada",
    path: "/:theme()/dashboard/venda/alugacell/v1/retirada-confirmada",
    component: () => import("/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/venda/alugacell/v1/retirada-confirmada.vue")
  },
  {
    name: "theme-dashboard-venda-aparelhos-v1-analise-de-credito",
    path: "/:theme()/dashboard/venda/aparelhos/v1/analise-de-credito",
    component: () => import("/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/venda/aparelhos/v1/analise-de-credito/index.vue")
  },
  {
    name: "theme-dashboard-venda-aparelhos-v1-conclusao-de-pedido",
    path: "/:theme()/dashboard/venda/aparelhos/v1/conclusao-de-pedido",
    component: () => import("/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/venda/aparelhos/v1/conclusao-de-pedido/index.vue")
  },
  {
    name: "theme-dashboard-venda-aparelhos-v1-confirmacao-de-venda",
    path: "/:theme()/dashboard/venda/aparelhos/v1/confirmacao-de-venda",
    component: () => import("/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/venda/aparelhos/v1/confirmacao-de-venda/index.vue")
  },
  {
    name: "theme-dashboard-venda-aparelhos-v1-dados-do-cliente",
    path: "/:theme()/dashboard/venda/aparelhos/v1/dados-do-cliente",
    component: () => import("/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/venda/aparelhos/v1/dados-do-cliente/index.vue")
  },
  {
    name: "theme-dashboard-venda-aparelhos-v1-personalizacao-de-pagamento",
    path: "/:theme()/dashboard/venda/aparelhos/v1/personalizacao-de-pagamento",
    component: () => import("/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/venda/aparelhos/v1/personalizacao-de-pagamento/index.vue")
  },
  {
    name: "theme-dashboard-venda-aparelhos-v1-planos",
    path: "/:theme()/dashboard/venda/aparelhos/v1/planos",
    component: () => import("/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/venda/aparelhos/v1/planos/index.vue")
  },
  {
    name: "theme-dashboard-venda-edicao-de-venda-id",
    path: "/:theme()/dashboard/venda/edicao-de-venda/:id()",
    component: () => import("/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/venda/edicao-de-venda/[id]/index.vue")
  },
  {
    name: "theme-dashboard-venda-escolha-da-categoria",
    path: "/:theme()/dashboard/venda/escolha-da-categoria",
    component: () => import("/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/venda/escolha-da-categoria/index.vue")
  },
  {
    name: "theme-dashboard-venda-escolha-da-operadora",
    path: "/:theme()/dashboard/venda/escolha-da-operadora",
    component: () => import("/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/venda/escolha-da-operadora/index.vue")
  },
  {
    name: "theme-dashboard-venda-escolha-do-tipo-de-venda",
    path: "/:theme()/dashboard/venda/escolha-do-tipo-de-venda",
    component: () => import("/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/venda/escolha-do-tipo-de-venda/index.vue")
  },
  {
    name: "theme-dashboard-venda-seguros-residencial-v1-analise-de-credito",
    path: "/:theme()/dashboard/venda/seguros-residencial/v1/analise-de-credito",
    component: () => import("/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/venda/seguros-residencial/v1/analise-de-credito/index.vue")
  },
  {
    name: "theme-dashboard-venda-seguros-residencial-v1-conclusao-de-pedido",
    path: "/:theme()/dashboard/venda/seguros-residencial/v1/conclusao-de-pedido",
    component: () => import("/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/venda/seguros-residencial/v1/conclusao-de-pedido/index.vue")
  },
  {
    name: "theme-dashboard-venda-seguros-residencial-v1-confirmacao-de-venda",
    path: "/:theme()/dashboard/venda/seguros-residencial/v1/confirmacao-de-venda",
    component: () => import("/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/venda/seguros-residencial/v1/confirmacao-de-venda/index.vue")
  },
  {
    name: "theme-dashboard-venda-seguros-residencial-v1-dados-do-cliente",
    path: "/:theme()/dashboard/venda/seguros-residencial/v1/dados-do-cliente",
    component: () => import("/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/venda/seguros-residencial/v1/dados-do-cliente/index.vue")
  },
  {
    name: "theme-dashboard-venda-seguros-residencial-v1-personalizacao-de-pagamento",
    path: "/:theme()/dashboard/venda/seguros-residencial/v1/personalizacao-de-pagamento",
    component: () => import("/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/venda/seguros-residencial/v1/personalizacao-de-pagamento/index.vue")
  },
  {
    name: "theme-dashboard-venda-seguros-residencial-v1-planos",
    path: "/:theme()/dashboard/venda/seguros-residencial/v1/planos",
    component: () => import("/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/venda/seguros-residencial/v1/planos/index.vue")
  },
  {
    name: "theme-dashboard-venda-seguros-v1-analise-de-credito",
    path: "/:theme()/dashboard/venda/seguros/v1/analise-de-credito",
    component: () => import("/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/venda/seguros/v1/analise-de-credito/index.vue")
  },
  {
    name: "theme-dashboard-venda-seguros-v1-conclusao-de-pedido",
    path: "/:theme()/dashboard/venda/seguros/v1/conclusao-de-pedido",
    component: () => import("/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/venda/seguros/v1/conclusao-de-pedido/index.vue")
  },
  {
    name: "theme-dashboard-venda-seguros-v1-confirmacao-de-venda",
    path: "/:theme()/dashboard/venda/seguros/v1/confirmacao-de-venda",
    component: () => import("/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/venda/seguros/v1/confirmacao-de-venda/index.vue")
  },
  {
    name: "theme-dashboard-venda-seguros-v1-dados-do-cliente",
    path: "/:theme()/dashboard/venda/seguros/v1/dados-do-cliente",
    component: () => import("/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/venda/seguros/v1/dados-do-cliente/index.vue")
  },
  {
    name: "theme-dashboard-venda-seguros-v1-personalizacao-de-pagamento",
    path: "/:theme()/dashboard/venda/seguros/v1/personalizacao-de-pagamento",
    component: () => import("/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/venda/seguros/v1/personalizacao-de-pagamento/index.vue")
  },
  {
    name: "theme-dashboard-venda-seguros-v1-planos",
    path: "/:theme()/dashboard/venda/seguros/v1/planos",
    component: () => import("/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/venda/seguros/v1/planos/index.vue")
  },
  {
    name: "theme-dashboard-venda-seguros-v1-verificacao-usuario",
    path: "/:theme()/dashboard/venda/seguros/v1/verificacao-usuario",
    component: () => import("/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/venda/seguros/v1/verificacao-usuario/index.vue")
  },
  {
    name: "theme-dashboard-venda-terra-internet-fixa-v1-analise-de-cobertura",
    path: "/:theme()/dashboard/venda/terra-internet-fixa/v1/analise-de-cobertura",
    component: () => import("/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/venda/terra-internet-fixa/v1/analise-de-cobertura/index.vue")
  },
  {
    name: "theme-dashboard-venda-terra-internet-fixa-v1-analise-de-credito",
    path: "/:theme()/dashboard/venda/terra-internet-fixa/v1/analise-de-credito",
    component: () => import("/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/venda/terra-internet-fixa/v1/analise-de-credito/index.vue")
  },
  {
    name: "theme-dashboard-venda-terra-internet-fixa-v1-conclusao-de-pedido",
    path: "/:theme()/dashboard/venda/terra-internet-fixa/v1/conclusao-de-pedido",
    component: () => import("/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/venda/terra-internet-fixa/v1/conclusao-de-pedido/index.vue")
  },
  {
    name: "theme-dashboard-venda-terra-internet-fixa-v1-dados-do-cliente",
    path: "/:theme()/dashboard/venda/terra-internet-fixa/v1/dados-do-cliente",
    component: () => import("/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/venda/terra-internet-fixa/v1/dados-do-cliente/index.vue")
  },
  {
    name: "theme-dashboard-venda-terra-internet-fixa-v1-personalizacao-de-pagamento",
    path: "/:theme()/dashboard/venda/terra-internet-fixa/v1/personalizacao-de-pagamento",
    component: () => import("/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/venda/terra-internet-fixa/v1/personalizacao-de-pagamento/index.vue")
  },
  {
    name: "theme-dashboard-venda-terra-internet-fixa-v1-planos",
    path: "/:theme()/dashboard/venda/terra-internet-fixa/v1/planos",
    component: () => import("/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/venda/terra-internet-fixa/v1/planos/index.vue")
  },
  {
    name: "theme-dashboard-venda-terra-movel-v1-abertura-da-venda",
    path: "/:theme()/dashboard/venda/terra-movel/v1/abertura-da-venda",
    component: () => import("/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/venda/terra-movel/v1/abertura-da-venda/index.vue")
  },
  {
    name: "theme-dashboard-venda-terra-movel-v1-abertura-da-venda-line-activation-warn",
    path: "/:theme()/dashboard/venda/terra-movel/v1/abertura-da-venda/line-activation-warn",
    component: () => import("/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/venda/terra-movel/v1/abertura-da-venda/line-activation-warn.vue")
  },
  {
    name: "theme-dashboard-venda-terra-movel-v1-abertura-da-venda-migration",
    path: "/:theme()/dashboard/venda/terra-movel/v1/abertura-da-venda/migration",
    component: () => import("/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/venda/terra-movel/v1/abertura-da-venda/migration.vue")
  },
  {
    name: "theme-dashboard-venda-terra-movel-v1-abertura-da-venda-new-line",
    path: "/:theme()/dashboard/venda/terra-movel/v1/abertura-da-venda/new-line",
    component: () => import("/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/venda/terra-movel/v1/abertura-da-venda/new-line.vue")
  },
  {
    name: "theme-dashboard-venda-terra-movel-v1-abertura-da-venda-portability",
    path: "/:theme()/dashboard/venda/terra-movel/v1/abertura-da-venda/portability",
    component: () => import("/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/venda/terra-movel/v1/abertura-da-venda/portability.vue")
  },
  {
    name: "theme-dashboard-venda-terra-movel-v1-analise-de-credito",
    path: "/:theme()/dashboard/venda/terra-movel/v1/analise-de-credito",
    component: () => import("/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/venda/terra-movel/v1/analise-de-credito/index.vue")
  },
  {
    name: "theme-dashboard-venda-terra-movel-v1-confirmacao-de-venda",
    path: "/:theme()/dashboard/venda/terra-movel/v1/confirmacao-de-venda",
    component: () => import("/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/venda/terra-movel/v1/confirmacao-de-venda/index.vue")
  },
  {
    name: "theme-dashboard-venda-terra-movel-v1-dados-do-cliente",
    path: "/:theme()/dashboard/venda/terra-movel/v1/dados-do-cliente",
    component: () => import("/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/venda/terra-movel/v1/dados-do-cliente/index.vue")
  },
  {
    name: "theme-dashboard-venda-terra-movel-v1-personalizacao-de-pagamento",
    path: "/:theme()/dashboard/venda/terra-movel/v1/personalizacao-de-pagamento",
    component: () => import("/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/venda/terra-movel/v1/personalizacao-de-pagamento/index.vue")
  },
  {
    name: "theme-dashboard-venda-terra-movel-v1-planos",
    path: "/:theme()/dashboard/venda/terra-movel/v1/planos",
    component: () => import("/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/venda/terra-movel/v1/planos/index.vue")
  },
  {
    name: "theme-dashboard-venda-vivo-controle-cartao-v1-abertura-da-venda",
    path: "/:theme()/dashboard/venda/vivo-controle-cartao/v1/abertura-da-venda",
    component: () => import("/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/venda/vivo-controle-cartao/v1/abertura-da-venda/index.vue")
  },
  {
    name: "theme-dashboard-venda-vivo-controle-cartao-v1-abertura-da-venda-line-activation-warn",
    path: "/:theme()/dashboard/venda/vivo-controle-cartao/v1/abertura-da-venda/line-activation-warn",
    component: () => import("/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/venda/vivo-controle-cartao/v1/abertura-da-venda/line-activation-warn.vue")
  },
  {
    name: "theme-dashboard-venda-vivo-controle-cartao-v1-abertura-da-venda-migration",
    path: "/:theme()/dashboard/venda/vivo-controle-cartao/v1/abertura-da-venda/migration",
    component: () => import("/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/venda/vivo-controle-cartao/v1/abertura-da-venda/migration.vue")
  },
  {
    name: "theme-dashboard-venda-vivo-controle-cartao-v1-abertura-da-venda-new-line",
    path: "/:theme()/dashboard/venda/vivo-controle-cartao/v1/abertura-da-venda/new-line",
    component: () => import("/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/venda/vivo-controle-cartao/v1/abertura-da-venda/new-line.vue")
  },
  {
    name: "theme-dashboard-venda-vivo-controle-cartao-v1-abertura-da-venda-portability",
    path: "/:theme()/dashboard/venda/vivo-controle-cartao/v1/abertura-da-venda/portability",
    component: () => import("/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/venda/vivo-controle-cartao/v1/abertura-da-venda/portability.vue")
  },
  {
    name: "theme-dashboard-venda-vivo-controle-cartao-v1-analise-de-credito",
    path: "/:theme()/dashboard/venda/vivo-controle-cartao/v1/analise-de-credito",
    component: () => import("/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/venda/vivo-controle-cartao/v1/analise-de-credito/index.vue")
  },
  {
    name: "theme-dashboard-venda-vivo-controle-cartao-v1-confirmacao-de-venda",
    path: "/:theme()/dashboard/venda/vivo-controle-cartao/v1/confirmacao-de-venda",
    component: () => import("/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/venda/vivo-controle-cartao/v1/confirmacao-de-venda/index.vue")
  },
  {
    name: "theme-dashboard-venda-vivo-controle-cartao-v1-dados-do-cliente",
    path: "/:theme()/dashboard/venda/vivo-controle-cartao/v1/dados-do-cliente",
    component: () => import("/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/venda/vivo-controle-cartao/v1/dados-do-cliente/index.vue")
  },
  {
    name: "theme-dashboard-venda-vivo-controle-cartao-v1-personalizacao-de-pagamento",
    path: "/:theme()/dashboard/venda/vivo-controle-cartao/v1/personalizacao-de-pagamento",
    component: () => import("/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/venda/vivo-controle-cartao/v1/personalizacao-de-pagamento/index.vue")
  },
  {
    name: "theme-dashboard-venda-vivo-controle-cartao-v1-planos",
    path: "/:theme()/dashboard/venda/vivo-controle-cartao/v1/planos",
    component: () => import("/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/venda/vivo-controle-cartao/v1/planos/index.vue")
  },
  {
    name: "theme-dashboard-venda-vivo-movel-v1-abertura-da-venda",
    path: "/:theme()/dashboard/venda/vivo-movel/v1/abertura-da-venda",
    component: () => import("/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/venda/vivo-movel/v1/abertura-da-venda/index.vue")
  },
  {
    name: "theme-dashboard-venda-vivo-movel-v1-abertura-da-venda-line-activation-warn",
    path: "/:theme()/dashboard/venda/vivo-movel/v1/abertura-da-venda/line-activation-warn",
    component: () => import("/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/venda/vivo-movel/v1/abertura-da-venda/line-activation-warn.vue")
  },
  {
    name: "theme-dashboard-venda-vivo-movel-v1-abertura-da-venda-migration",
    path: "/:theme()/dashboard/venda/vivo-movel/v1/abertura-da-venda/migration",
    component: () => import("/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/venda/vivo-movel/v1/abertura-da-venda/migration.vue")
  },
  {
    name: "theme-dashboard-venda-vivo-movel-v1-abertura-da-venda-new-line",
    path: "/:theme()/dashboard/venda/vivo-movel/v1/abertura-da-venda/new-line",
    component: () => import("/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/venda/vivo-movel/v1/abertura-da-venda/new-line.vue")
  },
  {
    name: "theme-dashboard-venda-vivo-movel-v1-abertura-da-venda-portability",
    path: "/:theme()/dashboard/venda/vivo-movel/v1/abertura-da-venda/portability",
    component: () => import("/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/venda/vivo-movel/v1/abertura-da-venda/portability.vue")
  },
  {
    name: "theme-dashboard-venda-vivo-movel-v1-analise-de-credito",
    path: "/:theme()/dashboard/venda/vivo-movel/v1/analise-de-credito",
    component: () => import("/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/venda/vivo-movel/v1/analise-de-credito/index.vue")
  },
  {
    name: "theme-dashboard-venda-vivo-movel-v1-conclusao-de-pedido",
    path: "/:theme()/dashboard/venda/vivo-movel/v1/conclusao-de-pedido",
    component: () => import("/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/venda/vivo-movel/v1/conclusao-de-pedido/index.vue")
  },
  {
    name: "theme-dashboard-venda-vivo-movel-v1-confirmacao-de-venda",
    path: "/:theme()/dashboard/venda/vivo-movel/v1/confirmacao-de-venda",
    component: () => import("/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/venda/vivo-movel/v1/confirmacao-de-venda/index.vue")
  },
  {
    name: "theme-dashboard-venda-vivo-movel-v1-dados-do-cliente",
    path: "/:theme()/dashboard/venda/vivo-movel/v1/dados-do-cliente",
    component: () => import("/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/venda/vivo-movel/v1/dados-do-cliente/index.vue")
  },
  {
    name: "theme-dashboard-venda-vivo-movel-v1-personalizacao-de-pagamento",
    path: "/:theme()/dashboard/venda/vivo-movel/v1/personalizacao-de-pagamento",
    component: () => import("/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/venda/vivo-movel/v1/personalizacao-de-pagamento/index.vue")
  },
  {
    name: "theme-dashboard-venda-vivo-movel-v1-planos",
    path: "/:theme()/dashboard/venda/vivo-movel/v1/planos",
    component: () => import("/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/venda/vivo-movel/v1/planos/index.vue")
  },
  {
    name: "theme-dashboard-venda-vivo-residencial-v1-analise-de-credito",
    path: "/:theme()/dashboard/venda/vivo-residencial/v1/analise-de-credito",
    component: () => import("/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/venda/vivo-residencial/v1/analise-de-credito/index.vue")
  },
  {
    name: "theme-dashboard-venda-vivo-residencial-v1-conclusao-de-pedido",
    path: "/:theme()/dashboard/venda/vivo-residencial/v1/conclusao-de-pedido",
    component: () => import("/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/venda/vivo-residencial/v1/conclusao-de-pedido/index.vue")
  },
  {
    name: "theme-dashboard-venda-vivo-residencial-v1-confirmacao-de-venda",
    path: "/:theme()/dashboard/venda/vivo-residencial/v1/confirmacao-de-venda",
    component: () => import("/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/venda/vivo-residencial/v1/confirmacao-de-venda/index.vue")
  },
  {
    name: "theme-dashboard-venda-vivo-residencial-v1-dados-do-cliente",
    path: "/:theme()/dashboard/venda/vivo-residencial/v1/dados-do-cliente",
    component: () => import("/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/venda/vivo-residencial/v1/dados-do-cliente/index.vue")
  },
  {
    name: "theme-dashboard-venda-vivo-residencial-v1-personalizacao-de-pagamento",
    path: "/:theme()/dashboard/venda/vivo-residencial/v1/personalizacao-de-pagamento",
    component: () => import("/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/venda/vivo-residencial/v1/personalizacao-de-pagamento/index.vue")
  },
  {
    name: "theme-dashboard-venda-vivo-residencial-v1-planos",
    path: "/:theme()/dashboard/venda/vivo-residencial/v1/planos",
    component: () => import("/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/dashboard/venda/vivo-residencial/v1/planos/index.vue")
  },
  {
    name: "theme-faq",
    path: "/:theme()/faq",
    component: () => import("/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/faq/index.vue")
  },
  {
    name: "theme",
    path: "/:theme()",
    component: () => import("/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/index.vue")
  },
  {
    name: "theme-login",
    path: "/:theme()/login",
    component: () => createClientPage(() => import("/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/login/index.client.vue"))
  },
  {
    name: "theme-recuperacao-de-senha",
    path: "/:theme()/recuperacao-de-senha",
    component: () => import("/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/[theme]/recuperacao-de-senha/index.vue")
  },
  {
    name: "alugacell-aceite-de-venda",
    path: "/alugacell/aceite-de-venda",
    component: () => import("/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/alugacell/aceite-de-venda.vue")
  },
  {
    name: "dispositivos-aceite-de-venda",
    path: "/dispositivos/aceite-de-venda",
    component: () => import("/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/dispositivos/aceite-de-venda/index.vue")
  },
  {
    name: "dispositivos-venda-aceita",
    path: "/dispositivos/venda-aceita",
    component: () => import("/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/dispositivos/venda-aceita/index.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/index.vue")
  },
  {
    name: "r-user-offer-pdv",
    path: "/r/:user()/:offer()/:pdv()",
    component: () => import("/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/r/[user]/[offer]/[pdv]/index.vue")
  },
  {
    name: "r-user-offer",
    path: "/r/:user()/:offer()",
    component: () => import("/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/r/[user]/[offer]/index.vue")
  },
  {
    name: "seguros-residencial-aceite-de-venda",
    path: "/seguros-residencial/aceite-de-venda",
    component: () => import("/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/seguros-residencial/aceite-de-venda/index.vue")
  },
  {
    name: "seguros-residencial-venda-aceita",
    path: "/seguros-residencial/venda-aceita",
    component: () => import("/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/seguros-residencial/venda-aceita/index.vue")
  },
  {
    name: "seguros-aceite-de-venda",
    path: "/seguros/aceite-de-venda",
    component: () => import("/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/seguros/aceite-de-venda/index.vue")
  },
  {
    name: "seguros-venda-aceita",
    path: "/seguros/venda-aceita",
    component: () => import("/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/seguros/venda-aceita/index.vue")
  },
  {
    name: "t-promoter-offer-pdv",
    path: "/t/:promoter()/:offer()/:pdv()",
    component: () => import("/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/t/[promoter]/[offer]/[pdv]/index.vue")
  },
  {
    name: "t-promoter-offer",
    path: "/t/:promoter()/:offer()",
    component: () => import("/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/t/[promoter]/[offer]/index.vue")
  },
  {
    name: "terra-agendamento",
    path: "/terra/agendamento",
    component: () => import("/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/terra/agendamento/index.vue")
  },
  {
    name: "terra-internet-fixa-aceite-de-venda",
    path: "/terra/internet-fixa/aceite-de-venda",
    component: () => import("/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/terra/internet-fixa/aceite-de-venda/index.vue")
  },
  {
    name: "terra-internet-fixa-aceite-finalizado",
    path: "/terra/internet-fixa/aceite-finalizado",
    component: () => import("/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/terra/internet-fixa/aceite-finalizado/index.vue")
  },
  {
    name: "terra-internet-fixa-aceite-recusado",
    path: "/terra/internet-fixa/aceite-recusado",
    component: () => import("/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/terra/internet-fixa/aceite-recusado/index.vue")
  },
  {
    name: "terra-internet-fixa-venda-aceita",
    path: "/terra/internet-fixa/venda-aceita",
    component: () => import("/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/pages/terra/internet-fixa/venda-aceita/index.vue")
  }
]