import payload_plugin_o5Dmrs0OlI from "/opt/teamcity/buildAgent/work/f8391997fb1b84f4/node_modules/.pnpm/@pinia+nuxt@0.9.0_magicast@0.3.5_pinia@2.3.1_typescript@5.7.3_vue@3.5.13_typescript@5.7.3__/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_BHFlUGIsYV from "/opt/teamcity/buildAgent/work/f8391997fb1b84f4/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.10.1_db0@0.2.4_eslint@9.20.1_jiti@2.4._912839802084c35a1ec6aad15785acb3/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_gaI1wUiVXU from "/opt/teamcity/buildAgent/work/f8391997fb1b84f4/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.10.1_db0@0.2.4_eslint@9.20.1_jiti@2.4._912839802084c35a1ec6aad15785acb3/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_RCSGxDBc8y from "/opt/teamcity/buildAgent/work/f8391997fb1b84f4/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.10.1_db0@0.2.4_eslint@9.20.1_jiti@2.4._912839802084c35a1ec6aad15785acb3/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_ss9lkhOgne from "/opt/teamcity/buildAgent/work/f8391997fb1b84f4/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.10.1_db0@0.2.4_eslint@9.20.1_jiti@2.4._912839802084c35a1ec6aad15785acb3/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_KfWgO0mv16 from "/opt/teamcity/buildAgent/work/f8391997fb1b84f4/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.10.1_db0@0.2.4_eslint@9.20.1_jiti@2.4._912839802084c35a1ec6aad15785acb3/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8l3NV2Fryt from "/opt/teamcity/buildAgent/work/f8391997fb1b84f4/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.10.1_db0@0.2.4_eslint@9.20.1_jiti@2.4._912839802084c35a1ec6aad15785acb3/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_OfxMSjKIaj from "/opt/teamcity/buildAgent/work/f8391997fb1b84f4/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.10.1_db0@0.2.4_eslint@9.20.1_jiti@2.4._912839802084c35a1ec6aad15785acb3/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_oZ9OcWupYR from "/opt/teamcity/buildAgent/work/f8391997fb1b84f4/node_modules/.pnpm/@pinia+nuxt@0.9.0_magicast@0.3.5_pinia@2.3.1_typescript@5.7.3_vue@3.5.13_typescript@5.7.3__/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/opt/teamcity/buildAgent/work/f8391997fb1b84f4/.nuxt/components.plugin.mjs";
import prefetch_client_itFCfLCDRD from "/opt/teamcity/buildAgent/work/f8391997fb1b84f4/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.10.1_db0@0.2.4_eslint@9.20.1_jiti@2.4._912839802084c35a1ec6aad15785acb3/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import slideovers_93KNZWsbLi from "/opt/teamcity/buildAgent/work/f8391997fb1b84f4/node_modules/.pnpm/@nuxt+ui@2.21.0_axios@1.7.9_change-case@5.4.4_magicast@0.3.5_typescript@5.7.3_vite@6.1._7a780d5bf1bdfb406f616ba61277636a/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.js";
import modals_OO5Y06YJsj from "/opt/teamcity/buildAgent/work/f8391997fb1b84f4/node_modules/.pnpm/@nuxt+ui@2.21.0_axios@1.7.9_change-case@5.4.4_magicast@0.3.5_typescript@5.7.3_vite@6.1._7a780d5bf1bdfb406f616ba61277636a/node_modules/@nuxt/ui/dist/runtime/plugins/modals.js";
import colors_tjTs9J3JKU from "/opt/teamcity/buildAgent/work/f8391997fb1b84f4/node_modules/.pnpm/@nuxt+ui@2.21.0_axios@1.7.9_change-case@5.4.4_magicast@0.3.5_typescript@5.7.3_vite@6.1._7a780d5bf1bdfb406f616ba61277636a/node_modules/@nuxt/ui/dist/runtime/plugins/colors.js";
import plugin_client_fxr5QpR3De from "/opt/teamcity/buildAgent/work/f8391997fb1b84f4/node_modules/.pnpm/@nuxtjs+color-mode@3.5.2_magicast@0.3.5/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_vCYlxyZwTK from "/opt/teamcity/buildAgent/work/f8391997fb1b84f4/node_modules/.pnpm/@nuxt+icon@1.10.3_magicast@0.3.5_vite@6.1.0_@types+node@22.10.1_jiti@2.4.2_terser@5.39._0e2e0dbea81f6747cd2258215d0717c4/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import plugin_wy0B721ODc from "/opt/teamcity/buildAgent/work/f8391997fb1b84f4/node_modules/.pnpm/@nuxtjs+device@3.2.4/node_modules/@nuxtjs/device/dist/runtime/plugin.js";
import plugin_WR3YhBZNm3 from "/opt/teamcity/buildAgent/work/f8391997fb1b84f4/node_modules/.pnpm/@pinia-plugin-persistedstate+nuxt@1.2.1_@pinia+nuxt@0.9.0_magicast@0.3.5_pinia@2.3.1_ty_98cace09389daa7226b9c36be06194bb/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.js";
import Vue3Lottie_client_2RwzQTxwz0 from "/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/plugins/Vue3Lottie.client.ts";
import maska_EdO7pVjy2z from "/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/plugins/maska.ts";
import vue_query_JC9gnf7CA1 from "/opt/teamcity/buildAgent/work/f8391997fb1b84f4/src/plugins/vue-query.ts";
export default [
  payload_plugin_o5Dmrs0OlI,
  revive_payload_client_BHFlUGIsYV,
  unhead_gaI1wUiVXU,
  router_RCSGxDBc8y,
  payload_client_ss9lkhOgne,
  navigation_repaint_client_KfWgO0mv16,
  check_outdated_build_client_8l3NV2Fryt,
  chunk_reload_client_OfxMSjKIaj,
  plugin_vue3_oZ9OcWupYR,
  components_plugin_KR1HBZs4kY,
  prefetch_client_itFCfLCDRD,
  slideovers_93KNZWsbLi,
  modals_OO5Y06YJsj,
  colors_tjTs9J3JKU,
  plugin_client_fxr5QpR3De,
  plugin_vCYlxyZwTK,
  plugin_wy0B721ODc,
  plugin_WR3YhBZNm3,
  Vue3Lottie_client_2RwzQTxwz0,
  maska_EdO7pVjy2z,
  vue_query_JC9gnf7CA1
]